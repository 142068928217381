<template>
    <div class="Download" :class="{ 'is-disabled': disabled, 'is-small': size === 'small' }">
        <div class="DownloadContent" @click="download">
            <span>{{ title }}</span>
            <IconBase :fill="svgFill" stroke="none">
                <IconPDF v-if="type === 'pdf'" />
                <IconCSV v-else />
            </IconBase>
        </div>
        <select v-model="selectedOption" :disabled="disabled" v-if="options.length > 0">
            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.label }}
            </option>
        </select>
    </div>
</template>

<script>
import IconBase from '../icons/IconBase';
import IconPDF from '../icons/IconPDF';
import IconCSV from '../icons/IconCSV';

export default {
    props: {
        size: {
            type: String,
            default: 'small',
        },
        title: String,
        type: String,
        disabled: Boolean,
        onDownload: {
            type: Function,
            default: () => ({}),
        },
        options: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            svgFill: '#979797',
            selectedOption: null, // To hold the current selected option
        };
    },
    components: {
        IconBase,
        IconPDF,
        IconCSV,
    },
    methods: {
        download() {
            if (this.disabled) {
                return;
            }

            this.$emit('onDownload', this.type, this.selectedOption);
            this.onDownload?.(this.type, this.selectedOption);
        },
    },
    mounted() {
        this.selectedOption = this.options.find(o => o.selected)?.value;
    },
};
</script>

<style lang="scss">
.theme-dark {
    .Download {
        &:hover {
            background-color: rgba(white, 0.1);
        }

        svg {
            fill: rgba(#fff, 0.75);
        }
    }
}

.Download {
    color: var(--color-text-black);
    font-weight: 700;
    display: flex;
    flex-direction: column;
    padding: 2px 0px;
    border-radius: 5px;
    border: solid 1px var(--color-text-inactive);
    cursor: pointer;

    .DownloadContent {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0px 12px;
    }

    select {
        background-color: transparent;
        border: none;
        font-family: inherit;
        font-size: 0.75rem;
        margin: -2px 12px 0 8px;
        color: var(--color-text-black);
    }

    &.is-small {
        padding: 4px 0px;

        span {
            font-size: 14px;
        }
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        border: solid 1px var(--color-text-inactive);

        span {
            color: var(--color-text-black);
        }

        svg {
            fill: var(--color-text-black);
        }
    }

    &.is-disabled {
        cursor: not-allowed !important;
        user-select: none !important;

        span {
            color: #ababab !important;
        }

        svg {
            fill: #ababab !important;
        }

        select {
            opacity: 0.6;
        }
    }

    span {
        margin-right: 5px;
        font-size: 0.95rem;
        margin-top: 1px;
        margin-bottom: 1px;
        display: block;
    }

    svg {
        fill: rgba(#000, 0.75);
    }
}
</style>
