var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Download",
    class: {
      'is-disabled': _vm.disabled,
      'is-small': _vm.size === 'small'
    }
  }, [_c('div', {
    staticClass: "DownloadContent",
    on: {
      "click": _vm.download
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.title))]), _c('IconBase', {
    attrs: {
      "fill": _vm.svgFill,
      "stroke": "none"
    }
  }, [_vm.type === 'pdf' ? _c('IconPDF') : _c('IconCSV')], 1)], 1), _vm.options.length > 0 ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedOption,
      expression: "selectedOption"
    }],
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedOption = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.options, function (option) {
    return _c('option', {
      key: option.value,
      domProps: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  }), 0) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }